import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { SidePanelContent, SidePanelTitle } from '../../../lib/components/side-panel-outlet';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';
import { yup, dayjs } from '../../../lib/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioGroup } from '../../../lib/components/form/radio-group';
import { TextField } from '../../../lib/components/form/text-field';
import { TextareaField } from '../../../lib/components/form/textarea-field';
import { Calendar } from '../../../lib/components/form/calendar';
import { ProcedureComboBox } from '../../../lib/components/procedure-combo-box';
import { PractitionerRadioGroup } from '../../../lib/components/practitioner-radio-group';
import {
  EnumRadioGroup,
  defaultMakeEnumRadioGroupOptions,
} from '../../../lib/components/enum-radio-group';
import { DateTime } from '../../../lib/components/date-time';
import { useGenericErrorsToast } from '../../../lib/components/toast';
import { useList } from '../../../lib/api/store/calendar';
import { LoadingSpinner } from '../../../lib/components/loading-spinner';
import { useMoveAppointment } from '../../../lib/api/actions/move-appointment';
import {
  AvailableSlotGroups,
  CalendarDayAvailability,
  FormSection,
  ProcedureFormActions,
  SelectedSlotDetails,
} from './shared';
import {
  CancellationReasonFormFields,
  determineDefaultCancellationTime,
} from '../../../lib/components/cancellation-reason-form-fields';

const MOVE_PROCEDURE_FORM_SCHEMA = yup.object().shape({
  nameGiven: yup.mixed(), // Displayed only
  nameFamily: yup.mixed(), // Displayed only
  phoneNumber: yup.mixed(), // Displayed only
  email: yup.mixed(), // Displayed only
  referralSource: yup.mixed(), // Displayed only
  procedureCodeId: yup.mixed(), // Displayed only
  appointmentNote: yup.mixed(), // Displayed only
  practitionerId: yup.string(), // Displayed only
  listType: yup.mixed(), // Displayed only
  selectedDate: yup.mixed(), // Displayed only
  calendarSlotDetails: yup
    .object({ calendarSlotId: yup.string().guid(), listId: yup.string().guid() })
    .required('Date selection is required'),
  cancellationTime: yup.string().required('Cancellation time is required'),
  cancellationReason: yup.string().required('Cancellation reason is required'),
  cancellationNotes: yup.string(),
});

export function MoveAppointment() {
  const { listId, appointmentId, date } = useParams();
  const navigate = useNavigate();
  const state = useList(listId);
  const calendarSlot = useMemo(
    () => state.data?.calendarSlots?.find((slot) => slot.appointment?.id === appointmentId),
    [appointmentId, state.data?.calendarSlots],
  );
  const genericErrorsToast = useGenericErrorsToast();

  const formDefaultValues = useMemo(
    () => ({
      nhiNumber: calendarSlot?.appointment?.patient?.nhiNumber ?? '',
      nameGiven: calendarSlot?.appointment?.patient?.nameGiven ?? '',
      nameFamily: calendarSlot?.appointment?.patient?.nameFamily ?? '',
      phoneNumber: calendarSlot?.appointment?.patient?.phoneNumber ?? '',
      email: calendarSlot?.appointment?.patient?.email ?? '',
      referralSource: calendarSlot?.appointment?.referralSource ?? '',
      procedureCodeId: calendarSlot?.appointment?.procedure?.procedureCode?.id ?? '',
      appointmentNote: calendarSlot?.appointment?.notes ?? '',
      practitionerId: 'any',
      listType: 'any',
      selectedDate: dayjs(calendarSlot?.arrivalTimeTimestamp).toISOString(),
      calendarSlotDetails: null,
      cancellationTime: determineDefaultCancellationTime(calendarSlot?.arrivalTimeTimestamp),
      cancellationReason: 'OtherUnknown',
      cancellationNotes: '',
    }),
    [calendarSlot],
  );

  const newProcedureForm = useForm({
    resolver: yupResolver(MOVE_PROCEDURE_FORM_SCHEMA),
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  // Save appointment
  const moveAppointment = useMoveAppointment();
  const moveAppointmentHandler = async (values) => {
    const { listId, calendarSlotId } = values.calendarSlotDetails;
    const { cancellationTime, cancellationReason, cancellationNotes } = values;
    invariant(calendarSlotId && listId);
    try {
      await moveAppointment.execute({
        appointmentId,
        calendarSlotId,
        cancellationTime,
        cancellationReason,
        cancellationNotes,
      });
      navigate(`/dashboard/${date}/details/${listId}`, {
        replace: true,
        state: { highlight: calendarSlotId },
      });
    } catch (error) {
      genericErrorsToast('Failed to move appointment', error);
    }
  };

  return !state.suspend && calendarSlot ? (
    <FormProvider {...newProcedureForm}>
      <SidePanelContent
        header={
          <div>
            <SidePanelTitle title="Find a new time for existing procedure" variant="fill" />
            <div className="bg-orange-50 px-6 py-3 border-b border-gray-200 text-sm font-semibold">
              Currently booked with {calendarSlot.list.practitioner.nameText} on{' '}
              <DateTime t={calendarSlot.arrivalTimeTimestamp} format="dddd, D MMMM" /> at{' '}
              <DateTime t={calendarSlot.arrivalTimeTimestamp} format="HH:mm" />
            </div>
          </div>
        }
        footer={
          <ProcedureFormActions submitHandler={moveAppointmentHandler} label="Move">
            <div className="flex gap-x-4 items-center">
              <CancellationReasonFormFields />
            </div>
          </ProcedureFormActions>
        }
      >
        <MoveAppointmentForm />
      </SidePanelContent>
    </FormProvider>
  ) : (
    <div className="text-gray-400 h-full w-full flex justify-center items-center">
      <LoadingSpinner />
    </div>
  );
}

function MoveAppointmentForm() {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col h-full max-h-full justify-stretch">
      <div className="grid xl:grid-cols-2 grow overflow-y-auto">
        <div>
          <FormSection title="NHI number">
            <div className="flex flex-row flex-nowrap space-x-3 w-full justify-stretch">
              <div className="flex-grow">
                <TextField field="nhiNumber" disabled className="uppercase" />
              </div>
            </div>
          </FormSection>
          <FormSection title="Patient name">
            <div>
              <TextField field="nameFamily" error={errors.lastName?.message} disabled />
            </div>
            <div></div>
            <div>
              <TextField field="nameGiven" error={errors.firstName?.message} disabled />
            </div>
          </FormSection>
          <FormSection title="Patient phone">
            <div>
              <TextField field="phoneNumber" error={errors.phoneNumber?.message} disabled />
            </div>
          </FormSection>
          <FormSection title="Patient email">
            <div>
              <TextField field="email" error={errors.email?.message} disabled />
            </div>
          </FormSection>
          <FormSection title="Referral source">
            <EnumRadioGroup
              name="ReferralSource"
              field="referralSource"
              error={errors.referralSource?.message}
              disabled
            />
          </FormSection>
          <FormSection title="Procedure">
            <ProcedureComboBox field="procedureCodeId" disabled />
          </FormSection>
          <FormSection title="Practitioner">
            <PractitionerRadioGroup field="practitionerId" />
          </FormSection>
          <FormSection title="List">
            <EnumRadioGroup
              name="ListType"
              field="listType"
              makeEnumRadioGroupOptions={(enumValues) => [
                <RadioGroup.LabelItem key="any" label="Any" />,
                ...defaultMakeEnumRadioGroupOptions(enumValues),
              ]}
            />
          </FormSection>
          <FormSection title="Appointment note">
            <TextareaField field="appointmentNote" disabled />
          </FormSection>
        </div>
        <div>
          <FormSection
            title="Calendar"
            subtitle={<label className="block text-gray-400 text-sm">Select a calendar day</label>}
          >
            <Calendar
              error={errors.selectedDate?.message}
              disablePastMonths={true}
              DayComponent={CalendarDayAvailability}
            />
          </FormSection>
          <FormSection title="Select an available time" subtitle={<SelectedSlotDetails />}>
            <AvailableSlotGroups />
          </FormSection>
        </div>
      </div>
    </div>
  );
}
MoveAppointmentForm.propTypes = {
  nhiSearchForm: yup.object().required().pt(),
  onNhiSearchFound: yup.mixed().callback().pt(),
};
